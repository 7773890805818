/*Root*/
:root {
  --reeler: #1ee9be;
  --reeler-light: #77f2d8;
  --reeler-grey: #f2f2f5;
  --reeler-grey-middle: #e2e2e9;
  --reeler-grey-dark: #d9d9d9;
  --reeler-container: #f2f2f5;
  --reeler-container-yellow: #f3f2ef;
  --reeler-link: #707070;
  --reeler-icon: #707070;
  --reeler-title: #4c4c4c;
  --reeler-badge: #4c4c4c;
  --reeler-black: #3c3c3c;
  --reeler-danger: #f97676;
  --reeler-danger-light: #fdbfbf;
  --reeler-orange: #ffb782;
  --reeler-blue: #7b97ff;
}
